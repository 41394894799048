import styled from "styled-components";
import { screenSizes } from "./variables";

const FooterItem = styled.div`
  max-width: 21rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${screenSizes.lg}) {
    margin-bottom: ${(props) => (props.last ? 0 : "2rem")};
  }
`;

export default FooterItem;
