import styled from "styled-components";
import { screenSizes } from "./variables";

const BlurbIconContainer = styled.div`
  margin: auto;
  margin-bottom: 2rem;
  @media screen and (max-width: ${screenSizes.sm}) {}
`;

export default BlurbIconContainer;
