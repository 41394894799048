import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Column } from "../ui/LayoutComponents";
import { ImageContainer } from "../ui";
import { PreviewCompatibleImage } from ".";
import { colors } from "../ui/variables";

const Title = styled.p`
  color: ${colors.mainText};
  font-size: 16px;
  font-weight: 800;
  line-height: initial;
  margin-top: 20px;
`;

const SubTitle = styled.p`
  color: ${colors.mainText};
  font-size: 16px;
  font-style: italic;
  text-align: center;
  letter-spacing: 0.6px;
  line-height: 25px;
`;

const Member = ({ member }) => {
  return (
    <Column flex={1}>
      <ImageContainer maxWidth="120px">
        <PreviewCompatibleImage
          imageInfo={member.image}
          style={{ borderRadius: "50%", boxShadow: "1px 1px 4px #ddd", marginTop: "3rem" }}
        />
      </ImageContainer>
      <Title>{member.name}</Title>
      <SubTitle>{member.function}</SubTitle>
    </Column>
  );
};

Member.prototypes = {
  member: PropTypes.array,
};

export default Member;
