import { createGlobalStyle } from "styled-components";
import { fonts, colors, screenSizes } from "./variables";

const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before { 
    box-sizing: border-box;
  }

  @import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300;400;500;600;700&family=DM+Sans:opsz,wght@9..40,400;9..40,600;9..40,700;9..40,900&family=Outfit:wght@400;500;700;800;900&display=swap');

  html {
    min-width: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  body {
    margin: 0;
    color: ${colors.mainText};
    font-family: ${fonts.dmSans};
    font-size: 17px;
  }

  p {
    color: ${colors.mainText};
    font-size: 18px;
    letter-spacing: 0.4px;
    line-height: 31px;
    white-space: pre-wrap;
    @media screen and (max-width: ${screenSizes.md}) {
      font-size: 16px;
      line-height: 27px;
    }
  }

  p + h2,
  ul + h2 {
    margin-top: 3.5rem;
  }

  p + h3,
  ul + h3 {
    margin-top: 2rem;
  }

  p + ul {
    margin-top: -12px;
  }

  ul {
    list-style: '– ' outside;
    margin-bottom: 18px;
    padding-left: 22px;
    li {
      font-size: 18px;
      letter-spacing: 0.4px;
      line-height: 30px;
      @media screen and (max-width: ${screenSizes.md}) {
        font-size: 16px;
        line-height: 27px;
      }
    }
  }

  a {
    color: ${colors.primaryBlue};
	  cursor: pointer;
	  text-decoration: none;
    &:hover {
      color: ${colors.primaryBlueHover};
    }
  }

  .attribution {
    color: ${colors.lightGrey};
    font-size: 16px;
    font-style: italic;
  }

  .site-link {
    color: #e95115;
    font-weight: 900;
    font-size: 20px;
    text-align: center;
    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
      color: #e95115;
    }
    @media screen and (max-width: ${screenSizes.md}) {
      font-size: 17px;
    }
  }

  .markdown {
    p {
      margin-bottom: 18px;
      &:last-child {
        margin-bottom: 0
      }
      img {
        display: block;
        margin: auto;
        text-align: center;
      }

    }

    h2 {
      color: ${colors.darkBlue};
      font-size: 25px;
      font-weight: 600;
      font-family: ${fonts.barlowCondensed};
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 2rem;
      @media screen and (max-width: ${screenSizes.sm}) {
        font-size: 16px;
        margin-bottom: 1rem;  
      }
    }

    h3 {
      color: ${colors.secondaryBlue};
      font-size: 19px;
      font-weight: 800;
      letter-spacing: 0.6px;
      margin-bottom: 14px;
      @media screen and ( max-width: ${screenSizes.lg} ) {
        font-size: 16px;
      }
    }
  }

  .interview {
    p {
      margin-bottom: 18px;
      &:last-child {
        margin-bottom: 0
      }
    }

    ul {
      list-style-type: disc;
    }

    li {
      margin-bottom: 10px;
      &::marker {
        font-weight: bold;
        color: ${colors.primaryBlue};
      }
    }

    h2 {
      color: ${colors.darkBlue};
      font-size: 25px;
      font-weight: 800;
      text-decoration: underline solid 3px ${colors.secondaryBlue};
      letter-spacing: 0.4px;
      line-height: 32px;
      margin-bottom: 2rem;
      @media screen and ( max-width: ${screenSizes.md} ) {
        font-size: 19px;
      }
    }

    h3 {
      color: ${colors.secondaryBlue};
      font-size: 18px;
      font-weight: 800;
      letter-spacing: 0.6px;
      margin-bottom: 14px;
      @media screen and ( max-width: ${screenSizes.lg} ) {
        font-size: 17px;
      }
    }
  }

  .h2-left {
    h2 {
      text-align: left;
    }
  }
  
  .h3-grey {
    h3 {
      color: ${colors.mainText};
      font-weight: 800;
    }
  }
`;

export default GlobalStyle;
