import styled from "styled-components";
import { Link } from "gatsby";
import { colors, screenSizes, fonts } from "./variables";

const { sm, md, lg } = screenSizes;

export const Text = styled.p`
  color: ${(props) => props.color || colors.darkBlue};
  font-size: ${(props) => props.size || "18px"};
  font-weight: ${(props) => props.weight || "400"};
  line-height: ${(props) => props.height || "auto"};
  ${(props) => props.center && `text-align: center;`}
  @media screen and (max-width: ${md}) {
    font-size: ${(props) => props.mobileSize || "16px"};
  }
`;

export const IntroText = styled.p`
  color: ${colors.introText};
  font-style: italic;
  text-align: justify;
  line-height: 27px;
  ${(props) => props.center && `text-align: center;`}
`;

export const LabelText = styled.p`
  color: ${colors.label};
  font-size: 16px;
  font-style: italic;
  ${(props) => props.center && `text-align: center;`};
  margin: ${(props) => props.margin || "1rem 0 0 0"};
`;

export const DateText = styled.p`
  color: ${colors.lightGrey};
  font-size: 17px;
  font-family: ${fonts.barlowCondensed};
  text-align: right;
  margin: ${(props) => props.margin || "1.5rem 0 0 0"};
  @media screen and (max-width: ${md}) {
    font-size: 14px;
    margin: ${(props) => props.margin || "1rem 0 0 0"};
  }
`;

export const TitleH1 = styled.h1`
  color: ${(props) => props.color || colors.secondaryBlue};
  font-size: ${(props) => props.size || "50px"};
  font-weight: 600;
  font-family: ${fonts.outfit};
  text-align: center;
  letter-spacing: 1px;
  line-height: initial;
  padding: ${(props) => props.padding || 0};
  margin: ${(props) => props.margin || 0};
  @media screen and (max-width: ${lg}) {
    font-size: ${(props) => props.sizeLg || "40px"};
  }
  @media screen and (max-width: ${md}) {
    font-size: ${(props) => props.sizeMd || "36px"};
  }
  @media screen and (max-width: ${sm}) {
    font-size: ${(props) => props.sizeSm || "28px"};
  }
`;

export const PageTitle = styled(TitleH1)`
  padding: 80px 0 0 0;
  @media screen and (max-width: ${lg}) {
    padding: 20px 0 0 0;
  }
  @media screen and (max-width: ${md}) {
    padding: 10px 0 0 0;
  }
`;

export const HeaderTitle = styled(TitleH1)`
  color: ${(props) => props.color || colors.white};
  text-shadow: ${(props) => (props.color ? "none" : "5px 5px 10px #222")};
  font-size: 60px;
  @media screen and (max-width: ${lg}) {
    font-size: 50px;
  }
  @media screen and (max-width: ${md}) {
    font-size: 40px;
  }
  @media screen and (max-width: ${sm}) {
    font-size: 34px;
  }
`;

export const PostTitle = styled(PageTitle)`
  font-size: 34px;
  line-height: 42px;
  ${(props) => props.outfit && `font-family: ${fonts.outfit}; color: ${colors.darkBlue}`};
  @media screen and (max-width: ${lg}) {
    font-size: 28px;
    line-height: 34px;
  }
  @media screen and (max-width: ${md}) {
    font-size: 24px;
    line-height: 30px;
  }
  @media screen and (max-width: ${sm}) {
    font-size: 21px;
    line-height: 26px;
  }
`;

export const SectionTitle = styled.h2`
  color: ${(props) => props.color || colors.darkBlue};
  font-size: ${(props) => props.size || "30px"};
  font-weight: 700;
  font-family: ${fonts.barlowCondensed};
  text-align: center;
  text-transform: uppercase;
  margin-bottom: ${(props) => props.marginB || "1.5rem"};
  @media screen and (max-width: ${sm}) {
    font-size: ${(props) => props.sizeMobile || "22px"};
  }
`;

export const TitleH3 = styled.h3`
  color: ${(props) => (props.blue ? colors.primaryBlue : colors.mainText)};
  font-size: 17px;
  font-weight: 800;
  letter-spacing: 0.4px;
  margin-bottom: 1rem;
`;

export const BlurbTitle = styled.h3`
  color: ${(props) => props.color || colors.secondaryBlue};
  font-size: ${(props) => props.size || "26px"};
  font-weight: 800;
  font-family: ${fonts.outfit};
  text-align: center;
  text-transform: uppercase;
  margin-bottom: ${(props) => props.marginB || "1.5rem"};
  @media screen and (max-width: ${sm}) {
    font-size: ${(props) => props.sizeMobile || "28px"};
  }
`;

export const SubtitleBlue = styled.p`
  color: ${colors.primaryBlue};
  text-align: right;
  font-style: italic;
  margin-top: 2rem;
  @media screen and (max-width: ${sm}) {
    font-size: 14px;
    margin-top: 1.5rem;
  }
`;

export const FooterTitle = styled.p`
  color: ${colors.whiteE};
  font-size: 19px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 4px;
`;

export const FooterText = styled(Text)`
  color: ${colors.footerText};
  font-size: ${(props) => props.size || "15px"};
  text-align: center;
  line-height: 27px;
  @media screen and (max-width: ${md}) {
    font-size: ${(props) => props.size || "15px"};
  }
`;

export const FooterAnchor = styled.a`
  color: ${colors.footerText};
  font-size: ${(props) => props.size || "15px"};
  text-align: center;
  letter-spacing: 0.4px;
  line-height: 27px;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: ${(props) => (props.noHover ? colors.footerText : colors.whiteE)};
  }
`;

export const FooterLink = styled(Link)`
  color: ${colors.footerText};
  font-size: ${(props) => props.size || "15px"};
  text-align: center;
  letter-spacing: 0.4px;
  line-height: 27px;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: ${(props) => (props.noHover ? colors.footerText : colors.whiteE)};
  }
`;

export const Span = styled.span`
  color: ${(props) => props.color || colors.darkBlue};
  font-size: ${(props) => props.size || "18px"};
  ${(props) => props.center && `text-align: center;`}
  ${(props) => props.bold && `font-weight: bold;`}
  @media screen and (max-width: ${md}) {
    font-size: ${(props) => props.mobileSize || "17px"};
  }
`;
