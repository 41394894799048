import React from "react";
import PropTypes from "prop-types";
import { BlurbIconContainer, BlurbContainer } from "../ui";
import { Text, BlurbTitle } from "../ui/Texts";

const Blurb = ({ icon, color, title, text, last }) => (
  <BlurbContainer last={last}>
    <div>
      <img src={icon} style={{ maxWidth: "100px" }} />
    </div>
    <BlurbTitle color={color} size="24px" sizeMobile="20px" marginB="1rem">
      {title}
    </BlurbTitle>
    <Text center size="16px">
      {text}
    </Text>
  </BlurbContainer>
);

Blurb.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  text: PropTypes.string,
  last: PropTypes.bool,
};

export default Blurb;
