import styled from "styled-components";
import { Row } from "./LayoutComponents";
import { screenSizes } from "./variables";

const SoutienContainer = styled(Row)`
  align-items: center;
  @media screen and (min-width: ${screenSizes.lg}) {
    min-height: 250px;
  }
  @media screen and (max-width: ${screenSizes.lg}) {
    margin-bottom: 3rem;
  }
`;

export default SoutienContainer;
