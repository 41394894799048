import React from "react";
import PropTypes from "prop-types";
import { colors } from "../ui/variables";
import { HeaderContainer } from "../ui";
import { HeaderTitle, PageTitle } from "../ui/Texts";

const Header = ({ title, fallback, bgImage, bgPosition, titleColor }) => {
  if (bgImage) {
    return (
      <HeaderContainer bgPosition={bgPosition} bgImage={bgImage} fallback={fallback}>
        <HeaderTitle color={titleColor}>{title}</HeaderTitle>
      </HeaderContainer>
    );
  } else {
    return <PageTitle>{title}</PageTitle>;
  }
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  bgImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  bgPosition: PropTypes.string,
  fallback: PropTypes.string,
  titleColor: PropTypes.string,
};

Header.defaultProps = {
  fallback: colors.tempHeader,
};

export default Header;
