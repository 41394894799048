import React, { useState } from "react";
import PropTypes from "prop-types";
import { v4 as id } from "uuid";
import styled from "styled-components";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { PreviewCompatibleImage } from ".";
import { screenSizes } from "../ui/variables";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  margin-top: 3rem;
  @media screen and (max-width: ${screenSizes.lg}) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (max-width: ${screenSizes.md}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ImageOverlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: all 0.2s ease-in-out 0s;
`;

const PhotoWrapper = styled.div`
  position: relative;
  &:hover,
  &:focus {
    cursor: pointer;
    ${ImageOverlay} {
      opacity: 1;
    }
  }
`;

const Gallery = ({ photos }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openLightbox = (i) => (e) => {
    setPhotoIndex(i);
    setIsOpen(true);
  };

  return (
    <>
      <Container>
        {photos.map((photo, i) => (
          <PhotoWrapper onClick={openLightbox(i)} key={id()}>
            <PreviewCompatibleImage imageInfo={photo.image} style={{ height: "10rem" }} />
            <ImageOverlay />
          </PhotoWrapper>
        ))}
      </Container>
      {isOpen && (
        <Lightbox
          mainSrc={photos[photoIndex].image.image.childImageSharp.fluid.src}
          nextSrc={photos[(photoIndex + 1) % photos.length].image.image.childImageSharp.fluid.src}
          prevSrc={
            photos[(photoIndex + photos.length - 1) % photos.length].image.image.childImageSharp.fluid.src
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + photos.length - 1) % photos.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photos.length)}
        />
      )}
    </>
  );
};

Gallery.propTypes = {
  photos: PropTypes.array,
};

export default Gallery;
