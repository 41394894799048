export function getImageWidth(image) {
  const sizesArray = image.sizes.split(",");
  const lastSize = sizesArray[sizesArray.length - 1];
  return lastSize;
}

export function isDateLaterThanToday(date) {
  const dateToCompare = new Date(date);
  const today = new Date();
  return dateToCompare.getTime() > today.getTime();
}
