export const colors = {
  /* Couleurs identité visuelle */
  darkBlue: "#2e3640",
  primaryBlue: "#307bbf",
  primaryBlueHover: "#4899d9",
  secondaryBlue: "#4cb4e7",
  secondaryBlueLight: "#69BCE5",
  secondaryBluedarker: "#2593bd",
  terceryBlue: "#b7dbf5",
  terceryBlueLight: "#dbedfb",
  vermillon: "#ee7766",
  green: "#38bba1",

  /* Site */
  white: "#FFFFFF",
  whiteE: "#EEEEEE",
  greySection: "#F6F6F6",
  blueHeader: "#0a4775",
  tempHeader: "#f2f3f4",
  footerBg: "#2E3B42",
  footerIconBg: "#263036",
  border: "#DDD",

  mainText: "#555555",
  introText: "#656F77",
  label: "#7a7a7a",
  lightGrey: "#888",
  title: "#333333",
  titleh2: "#474747",
  footerText: "#96a7b0",
};

export const fonts = {
  lato: "'Lato', Roboto, sans-serif",
  nunito: "'Nunito', Roboto, sans-serif",
  francoisOne: "'Francois One', Roboto, sans-serif",
  outfit: "'Outfit', Roboto, sans-serif",
  barlowCondensed: "'Barlow Condensed', Roboto, sans-serif",
  dmSans: "'DM Sans', Roboto, sans-serif",
};

export const screenSizes = {
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1140px",
};

export const linearGradients = {
  home: "linear-gradient(to right, #174d68 0%, #1b5d81 50%, #1a587a 75%, #103a50 100%)",
  actions: "linear-gradient(to right, #ada8b1 0%, #a79a9c 50%, #9e9390 75%, #a1a2a5 100%)",
  actualites: "linear-gradient(to right, #7b7a7d 0%, #b3b7bb 50%, #acabb0 75%, #746c6a 100%)",
  adherer: "linear-gradient(to right, #7a7b7d 0%, #7e7878 50%, #765c54 75%, #724f40 100%)",
  donMoelleOsseuse: "linear-gradient(to right, #201c1c 0%, #5e5b59 50%, #6d6a67 75%, #5d5a59 100%)",
  maladieLhf: "linear-gradient(to right, #044164 0%, #094262 50%, #094a67 75%, #06364c 100%)",
  maladieRare: "linear-gradient(to right, #9ddeeb 0%, #5097d7 50%, #3e8fd5 75%, #69bbe2 100%)",
  nousConnaitre: "linear-gradient(to right, #887c74 0%, #8b827a 50%, #837469 75%, #887f7c 100%)",
  recherche: "linear-gradient(to right, #a4a7ad 0%, #636e78 50%, #657b8e 75%, #a7afbb 100%)",
  soutiens: "linear-gradient(to right, #005154 0%, #0b5a5d 50%, #467172 75%, #305151 100%)",
  temoignages: "linear-gradient(to right, #3c663c 0%, #738e78 50%, #a5a886 75%, #6f7e4e 100%)",
  // Définis à partir de la photo de header de chaque page grace au site
  // https://www.louisbourque.ca/Color-Extractor/
};
