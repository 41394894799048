import styled from "styled-components";
import { colors, screenSizes } from "./variables";

const Section = styled.section`
  ${(props) => props.grey && `background-color: ${colors.greySection};`}
  ${(props) => props.blue && `background-color: ${colors.terceryBlue};`}
  ${(props) => props.blueLight && `background-color: ${colors.terceryBlueLight};`}
  ${(props) => props.darkBlue && `background-color: ${colors.darkBlue};`}

  @media screen and (max-width: ${screenSizes.lg}) {
    padding: ${(props) => (props.paddingLg ? props.paddingLg : "60px 0")};
  }
`;

export default Section;
