import styled from "styled-components";
import { Link } from "gatsby";
import { colors, screenSizes } from "./variables";

export const BaseButton = styled(Link)`
  margin: ${(props) => props.margin || "initial"};
  padding: 11px 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.color || colors.darkBlue};
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  border-radius: 50px;
  line-height: initial;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
`;

export const OutlinedButton = styled(BaseButton)`
  background-color: transparent;
  border: 1px solid;
  margin: ${(props) => props.margin || "2.5rem 0 0 0"};
  padding: 12px 20px 9px 20px;
  transition: 0.5s;
  @media screen and (max-width: ${screenSizes.lg}) {
    padding: 9px 20px;
  }
  &:hover,
  &:focus,
  &:active {
    color: ${colors.white};
    background-color: ${colors.secondaryBlue};
    border-color: ${colors.secondaryBlue};
  }
`;

export const ContainedButton = styled(BaseButton)`
  color: ${colors.white};
  background-color: ${(props) => props.color || colors.secondaryBlue};
  border: 2px solid ${(props) => props.color || colors.secondaryBlue};
  margin: ${(props) => props.margin || "auto"};
  padding: 8.5px 12px 6px 12px;
  transition: 0.5s;
  @media screen and (max-width: ${screenSizes.lg}) {
    padding: 6px 12px;
  }
  &:hover,
  &:focus,
  &:active {
    color: ${(props) => (props.outlinedHover ? props.colors || colors.secondaryBlue : colors.white)};
    ${(props) => !props.outlinedHover && `border-color: ${props.hoverColor || colors.secondaryBluedarker}`};
    background-color: ${(props) =>
      props.outlinedHover ? "transparent" : props.hoverColor || colors.secondaryBluedarker};
  }
`;

export const BaseAnchor = styled.a`
  margin: ${(props) => props.margin || "initial"};
  padding: 11px 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.color || colors.mainText};
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  border-radius: 4px;
  line-height: initial;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
`;

export const OutlinedAnchor = styled(BaseAnchor)`
  background-color: transparent;
  border: 1px solid;
  margin: ${(props) => props.margin || "2.5rem 0 0 0"};
  transition: 0.5s;
  &:hover,
  &:focus,
  &:active {
    color: ${colors.white};
    background-color: ${colors.secondaryBlue};
    border-color: ${colors.secondaryBlue};
  }
`;

export const ContainedAnchor = styled(BaseAnchor)`
  color: ${colors.white};
  background-color: ${(props) => props.color || colors.secondaryBlue};
  border: 2px solid ${(props) => props.color || colors.secondaryBlue};
  margin: ${(props) => props.margin || "auto"};
  padding: 8px 12px;
  transition: 0.5s;
  &:hover,
  &:focus,
  &:active {
    color: ${(props) => (props.outlinedHover ? props.colors || colors.secondaryBlue : colors.white)};
    ${(props) => !props.outlinedHover && `border-color: ${props.hoverColor || colors.secondaryBluedarker}`};
    background-color: ${(props) =>
      props.outlinedHover ? "transparent" : props.hoverColor || colors.secondaryBluedarker};
  }
`;

export const FooterButton = styled(OutlinedAnchor)`
  margin: 10px 0 0 0;
  padding: 9px 12px 7px 12px;
  color: ${colors.whiteE};
  transition: 0.5s;
  @media screen and (max-width: ${screenSizes.lg}) {
    padding: 7px 12px;
  }
  &:hover,
  &:focus,
  &:active {
    color: ${colors.footerBg};
    background-color: ${colors.whiteE};
    border-color: ${colors.whiteE};
  }
`;
