import styled from "styled-components";
import { colors } from "./variables";

const FooterIconContainer = styled.div`
  background-color: ${colors.footerIconBg};
  color: ${colors.white};
  font-size: 18px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 3px;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    background-color: ${colors.secondaryBlue};
    transform: translateY(-3px);
  }
`;

export default FooterIconContainer;
