import styled from "styled-components";
import { screenSizes } from "./variables";

const HeaderContainer = styled.header`
  height: 350px;
  background: ${(props) => `url(${props.bgImage}), ${props.fallback}`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: ${(props) => props.bgPosition || "center"};
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${screenSizes.lg}) {
    height: 300px;
  }
  @media screen and (max-width: ${screenSizes.md}) {
    height: 250px;
  }
  @media screen and (max-width: ${screenSizes.sm}) {
    height: 180px;
  }
`;

export default HeaderContainer;
