import React, { Component } from "react";
import { Link } from "gatsby";

import logo from "../img/logo-lhf.svg";
import { ContainedButton } from "../ui/Buttons";

const Navbar = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  toggleHamburger = () => {
    this.setState(
      {
        active: !this.state.active,
      },
      () => {
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  };

  render() {
    const { navBarActiveClass } = this.state;

    return (
      <nav className="navbar is-transparent" role="navigation" aria-label="main-navigation">
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <img src={logo} alt="LHF Espoir" />
            </Link>
            <button
              className={`navbar-burger burger ${navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
              onKeyDown={() => this.toggleHamburger()}>
              <span />
              <span />
              <span />
            </button>
          </div>
          <div id="navMenu" className={`navbar-menu ${navBarActiveClass}`}>
            <div className="navbar-end">
              <div className="navbar-item has-dropdown is-hoverable">
                <button className="navbar-link">L'association</button>
                <div className="navbar-dropdown">
                  <Link className="navbar-item" activeClassName="page-active" to="/nous-connaitre">
                    Nous connaître
                  </Link>
                  <hr className="navbar-divider"></hr>
                  <Link className="navbar-item" activeClassName="page-active" to="/actions">
                    Nos actions
                  </Link>
                  <hr className="navbar-divider"></hr>
                  <Link className="navbar-item" activeClassName="page-active" to="/partenaires">
                    Nos partenaires
                  </Link>
                  <hr className="navbar-divider"></hr>
                  <Link className="navbar-item" activeClassName="page-active" to="/adherer">
                    Adhérer
                  </Link>
                </div>
              </div>
              <div className="navbar-item has-dropdown is-hoverable">
                <button className="navbar-link">La maladie de LHF</button>
                <div className="navbar-dropdown">
                  <Link className="navbar-item" activeClassName="page-active" to="/maladie-lhf">
                    A propos de la maladie
                  </Link>
                  <hr className="navbar-divider"></hr>
                  <Link className="navbar-item" activeClassName="page-active" to="/recherche">
                    La recherche
                  </Link>
                </div>
              </div>
              <div className="navbar-item has-dropdown is-hoverable">
                <button className="navbar-link">Les maladies rares</button>
                <div className="navbar-dropdown">
                  <Link className="navbar-item" activeClassName="page-active" to="/maladie-rare">
                    Qu'est-ce qu'une maladie rare
                  </Link>
                  <hr className="navbar-divider"></hr>
                  <Link className="navbar-item" activeClassName="page-active" to="/don-moelle-osseuse">
                    Le don de moelle osseuse
                  </Link>
                </div>
              </div>
              <div className="navbar-item has-dropdown is-hoverable">
                <button className="navbar-link">Témoignages</button>
                <div className="navbar-dropdown">
                  <Link className="navbar-item" activeClassName="page-active" to="/temoignages-lhf">
                    Maladie de LHF
                  </Link>
                  <hr className="navbar-divider"></hr>
                  <Link
                    className="navbar-item"
                    activeClassName="page-active"
                    to="/temoignages-maladies-rares">
                    Maladies rares
                  </Link>
                </div>
              </div>
              <Link className="navbar-item" activeClassName="page-active" to="/actualites">
                Actualités
              </Link>
              <ContainedButton
                to="/don"
                className="navbar-item"
                margin="auto auto auto 10px"
                style={{ width: "max-content" }}>
                Faire un don
              </ContainedButton>
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
