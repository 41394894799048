import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FooterIconContainer } from "../ui";

const FooterIconLink = ({ title, href, icon }) => (
  <a title={title} href={href} target="_blank" rel="noreferrer">
    <FooterIconContainer>
      <FontAwesomeIcon icon={icon} />
    </FooterIconContainer>
  </a>
);

FooterIconLink.protoTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.any,
};

export default FooterIconLink;
