import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors, fonts, screenSizes, linearGradients } from "./variables";
import { Container, Column } from "./LayoutComponents";

const Header = styled.div`
  height: 500px;
  background: ${(props) =>
    props.image ? `url(${props.image}), ${colors.terceryBlueLight}` : colors.terceryBlueLight};
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${screenSizes.md}) {
    height: 300px;
  }
  @media screen and (max-width: ${screenSizes.sm}) {
    height: 260px;
  }
`;

const Heading = styled.h1`
  color: ${colors.darkBlue};
  font-size: 40px;
  font-weight: 700;
  font-family: ${fonts.outfit};
  text-align: center;
  letter-spacing: 1.5px;
  @media screen and (max-width: ${screenSizes.xl}) {
    font-size: 32px;
  }
  @media screen and (max-width: ${screenSizes.md}) {
    font-size: 27px;
  }
  @media screen and (max-width: ${screenSizes.sm}) {
    font-size: 23px;
  }
`;

const SubHeading = styled.p`
  margin-top: 1.2rem;
  color: ${colors.darkBlue};
  font-size: 26px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 1px;
  @media screen and (max-width: ${screenSizes.xl}) {
    font-size: 21px;
  }
  @media screen and (max-width: ${screenSizes.sm}) {
    font-size: 17px;
  }
`;

const Button = styled.a`
  margin: 3rem auto 0 auto;
  padding: 3px 20px;
  background-color: ${colors.terceryBlueLight};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  font-size: 33px;
  font-weight: 700;
  font-family: ${fonts.outfit};
  text-transform: uppercase;
  text-align: center;
  border-radius: 3px;
  letter-spacing: 2px;
  line-height: initial;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: ${colors.terceryBlue};
    text-decoration: none;
  }
  @media screen and (max-width: ${screenSizes.xl}) {
    font-size: 23px;
    letter-spacing: 1px;
    margin-top: 2.5rem;
    padding: 3px 12px;
  }
  @media screen and (max-width: ${screenSizes.sm}) {
    font-size: 18px;
    margin-top: 1.4rem;
  }
`;

const HomeHeader = ({ backgroundImage, heading, subHeading, url, buttonContent }) => (
  <Header image={backgroundImage}></Header>
);

HomeHeader.propTypes = {
  backgroundImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  url: PropTypes.string,
  buttonContent: PropTypes.string,
};

export default HomeHeader;
