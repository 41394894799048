import styled from "styled-components";
import { screenSizes } from "./variables";

export const MainWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  height: 100%;
  max-width: ${(props) => (props.large ? "1140px" : "1000px")};
  @media screen and (max-width: ${screenSizes.lg}) {
    max-width: 920px;
  }
  @media screen and (max-width: ${screenSizes.md}) {
    max-width: 700px;
  }
  @media screen and (max-width: ${screenSizes.sm}) {
    max-width: 540px;
  }
`;

export const Wrapper = styled.div`
  ${(props) => props.color && `background-color: ${props.color};`}
  padding: ${(props) => (props.padding ? props.padding : "80px 0")};
  @media screen and (max-width: ${screenSizes.lg}) {
    padding: ${(props) => (props.paddingLg ? props.paddingLg : "40px 0")};
  }
`;

export const Row = styled.div`
  display: flex;
  flex: ${(props) => props.flex || "initial"};
  flex-direction: row;
  flex-wrap: ${(props) => props.wrap || "initial"};
  align-items: ${(props) => props.alignItems || "initial"};
  justify-content: ${(props) => props.justifyContent || "initial"};
  margin: ${(props) => props.margin || "initial"};
  @media screen and (max-width: ${screenSizes.lg}) {
    flex-direction: ${(props) => (props.mobile ? "column" : "row")};
    ${(props) => props.mobile && "align-items: center"};
  }
`;

export const Column = styled.div`
  display: flex;
  flex: ${(props) => props.flex || "initial"};
  flex-direction: column;
  align-items: ${(props) => props.alignItems || "center"};
  justify-content: ${(props) => props.justifyContent || "center"};
  margin: ${(props) => props.margin || "initial"};
  width: ${(props) => props.width || "100%"};
`;
