import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { colors, screenSizes } from "./variables";
import { Text } from "./Texts";

const Container = styled.article`
  border: 1px solid ${colors.border};
  border-radius: 5px;
  box-shadow: 3px 3px 5px #f5f5f5;
  transition: all 0.4s;
  &:hover,
  &:active,
  &:focus {
    box-shadow: 0 5px 15px 0 rgb(222, 222, 222);
    transform: translateY(-4px);
    h2,
    p {
      color: #333;
    }
  }
`;

const ImageContainer = styled.div`
  height: 220px;
  border-radius: 4px 4px 0 0;
  @media screen and (max-width: ${screenSizes.md}) {
    height: 300px;
  }
  @media screen and (max-width: ${screenSizes.sm}) {
    height: 220px;
  }
`;

const TextContainer = styled.div`
  padding: 1.5rem;
  @media screen and (max-width: ${screenSizes.md}) {
    padding-bottom: 2rem;
  }
`;

const Title = styled.h2`
  color: ${colors.mainText};
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0.4px;
  line-height: 25px;
  margin-bottom: 1rem;
  @media screen and (max-width: ${screenSizes.sm}) {
    font-size: 18px;
    margin-bottom: 0.5rem;
  }
`;

const StyledText = styled(Text)`
  font-size: 16px;
  line-height: 25px;
`;

const Card = ({ title, text, link, children }) => (
  <Container>
    <Link to={link}>
      <ImageContainer>{children}</ImageContainer>
      <TextContainer>
        <Title>{title}</Title>
        <StyledText>{text}</StyledText>
      </TextContainer>
    </Link>
  </Container>
);

export default Card;
