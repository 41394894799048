import styled from "styled-components";
import { Column } from "./LayoutComponents";
import { screenSizes } from "./variables";

const BlurbContainer = styled(Column)`
  margin: 0 1rem;
  max-width: 18rem;
  @media screen and (max-width: ${screenSizes.lg}) {
    margin-bottom: ${(props) => (props.last ? 0 : "3rem")};
    max-width: 21rem;
  }
`;

export default BlurbContainer;
