import styled from "styled-components";
import { screenSizes } from "./variables";

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.two ? "repeat(2, 1fr)" : "repeat(3, 1fr)")};
  grid-gap: 2.5rem 2rem;
  @media screen and (max-width: 1059px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: ${screenSizes.md}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export default CardContainer;
