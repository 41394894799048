import styled from "styled-components";
import { screenSizes } from "./variables";

const ImageContainer = styled.div`
  width: 100%;
  max-width: ${(props) => props.maxWidth || "auto"};
  margin-top: ${(props) => props.marginT || 0};
  margin-bottom: ${(props) => props.marginB || 0};
  margin-right: ${(props) => (props.imgLeft ? props.margin : 0)};
  margin-left: ${(props) => (props.imgRight ? props.margin : 0)};
  @media screen and (max-width: ${screenSizes.lg}) {
    ${(props) => props.imgLeft && "margin-right: 0"};
    ${(props) => props.imgRight && "margin-left: 0"};
    margin-bottom: ${(props) =>
      props.imgLeft ? (props.marginMobile ? props.marginMobile : props.margin) : props.marginB || "auto"};
    margin-top: ${(props) =>
      props.imgRight ? (props.marginMobile ? props.marginMobile : props.margin) : props.marginT || "auto"};
  }
  @media screen and (max-width: ${screenSizes.md}) {
    max-width: ${(props) => props.maxWidthTab || props.maxWidth};
  }
  @media screen and (max-width: ${screenSizes.sm}) {
    max-width: ${(props) => props.maxWidthMobile || props.maxWidth};
  }
`;

export default ImageContainer;
