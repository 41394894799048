import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Row, Column } from "../ui/LayoutComponents";
import { PreviewCompatibleImage } from ".";
import { ImageContainer } from "../ui";
import { TitleH3, Text } from "../ui/Texts";
import { screenSizes } from "../ui/variables";

const StyledColumn = styled(Column)`
  align-items: flex-start;
  @media screen and (max-width: ${screenSizes.lg}) {
    align-items: center;
    & p {
      text-align: center;
    }
  }
`;

const MissionBlurb = ({ image, title, text }) => {
  return (
    <Row mobile margin="0 0 3.5rem 0">
      <ImageContainer imgLeft margin="2rem" maxWidth="130px">
        <PreviewCompatibleImage
          imageInfo={image}
          style={{ borderRadius: "50%", boxShadow: "1px 1px 5px #aaa" }}
        />
      </ImageContainer>
      <StyledColumn>
        <TitleH3>{title}</TitleH3>
        <Text>{text}</Text>
      </StyledColumn>
    </Row>
  );
};

MissionBlurb.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  text: PropTypes.string,
};

export default MissionBlurb;
