import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { colors, screenSizes, fonts } from "./variables";
import { Text } from "./Texts";

const Container = styled.article`
  border: 1px solid ${colors.border};
  border-radius: 5px;
  box-shadow: 3px 3px 5px #f5f5f5;
`;

const ImageContainer = styled.div`
  height: 220px;
  border-radius: 4px 4px 0 0;
  @media screen and (max-width: ${screenSizes.md}) {
    height: 300px;
  }
  @media screen and (max-width: ${screenSizes.sm}) {
    height: 220px;
  }
`;

const Title = styled.h2`
  color: ${colors.darkBlue};
  font-size: 20px;
  font-weight: 800;
  font-family: ${fonts.outfit}
  letter-spacing: 0.4px;
  line-height: 25px;
  transition: 0.2s;
  @media screen and (max-width: ${screenSizes.sm}) {
    font-size: 18px;
  }
  &:hover,
  &:active,
  &:focus {
    color: ${colors.secondaryBlueLight};
  }
`;

const Date = styled.p`
  color: #888;
  font-size: 13px;
  ${(props) => props.capitalize && "text-transform: capitalize"};
  margin-bottom: 0.8rem;
`;

const TextContainer = styled.div`
  padding: 1.5rem;
  box-sizing: content-box;
`;

const StyledText = styled(Text)`
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 1rem;
`;

const Button = styled(Link)`
  color: ${colors.secondaryBlue};
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  line-height: 25px;
  &:hover,
  &:active,
  &:focus {
    color: ${colors.secondaryBlue};
    text-decoration: underline;
  }
`;

const CardWithButton = ({
  title,
  actionStyle,
  date,
  text,
  link,
  buttonText = "Lire la suite >",
  children,
}) => (
  <Container>
    <Link to={link}>
      <ImageContainer>{children}</ImageContainer>
    </Link>
    <TextContainer>
      <Link to={link}>
        <Title>{title}</Title>
      </Link>
      <Date capitalize={actionStyle}>{date}</Date>
      <StyledText>{text}</StyledText>
      <Button to={link}>{buttonText}</Button>
    </TextContainer>
  </Container>
);

export default CardWithButton;
