import React from "react";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

import { FooterIconLink } from ".";
import { FooterItem } from "../ui";
import { FooterTitle, FooterText, FooterLink, FooterAnchor } from "../ui/Texts";
import { FooterButton } from "../ui/Buttons";
import { Container, Row } from "../ui/LayoutComponents";
import { colors } from "../ui/variables";

import logo from "../img/logo-blanc-bleu.svg";

const Footer = () => (
  <footer style={{ backgroundColor: colors.footerBg }}>
    <Container large>
      <Row mobile flex={1} margin="2rem 0" justifyContent="space-between">
        <FooterItem>
          <img src={logo} alt="LHF Espoir" style={{ width: "175px", margin: "0 0 1rem 0" }} />
          <Row>
            <FooterIconLink
              title="Facebook"
              href="https://www.facebook.com/lhf.espoir"
              icon={faFacebookF}></FooterIconLink>
            <FooterIconLink
              title="Instagram"
              href="https://www.instagram.com/lhfespoir"
              icon={faInstagram}></FooterIconLink>
            <FooterIconLink
              title="YouTube"
              href="https://www.youtube.com/channel/UCLEjA-MWLSTDty4LGFggCeQ"
              icon={faYoutube}></FooterIconLink>
            <FooterIconLink
              title="LinkedIn"
              href="https://www.linkedin.com/company/lhfespoir"
              icon={faLinkedinIn}></FooterIconLink>
          </Row>
        </FooterItem>
        <FooterItem>
          <FooterTitle>La Newsletter LHF</FooterTitle>
          <FooterText>
            Inscrivez-vous à notre newsletter et recevez chaque trimestre nos actualités.
          </FooterText>
          <FooterButton
            color={colors.white}
            href="https://lhfespoir.us10.list-manage.com/subscribe?u=980c466f8898fb0e2c2c3c856&id=7a9b9c1e8e"
            target="_blank">
            Je m'inscris
          </FooterButton>
        </FooterItem>
        <FooterItem last>
          <FooterTitle>Nous contacter</FooterTitle>
          <FooterAnchor noHover href="mailto:contact@lhfespoir.org">
            contact@lhfespoir.org
          </FooterAnchor>
          <FooterAnchor noHover href="tel:+33611605762">
            06 11 60 57 62
          </FooterAnchor>
          <FooterText>
            147 rue Garibaldi <br />
            94100 Saint-Maur-des-Fossés, France
          </FooterText>
        </FooterItem>
      </Row>
    </Container>
    <div style={{ backgroundColor: colors.footerIconBg }}>
      <Container large>
        <Row flex={1} margin="8px 0" justifyContent="center">
          {/* prettier-ignore */}
          <FooterText>
            © {new Date().getFullYear()} LHF Espoir - <FooterLink size="14px" to="/mentions-legales">Mentions légales</FooterLink>  - <FooterLink size="14px" to="/conditions-generales-utilisation-enpad">CGU eNPAD</FooterLink>
          </FooterText>
        </Row>
      </Container>
    </div>
  </footer>
);

export default Footer;
 