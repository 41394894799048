import React from "react";
import PropTypes from "prop-types";

import { GlobalStyle } from "../ui";
import { MainWrapper } from "../ui/LayoutComponents";
import { Navbar, Footer } from ".";
import "./all.sass";

// Fonts
import "typeface-lato";
import "typeface-nunito";
import "typeface-francois-one";

const Layout = ({ children }) => (
  <>
    <GlobalStyle />
    <MainWrapper>
      <Navbar />
      <main>{children}</main>
      <Footer />
    </MainWrapper>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
